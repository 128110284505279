// src/Perfil.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../../utils/supabase';
import { Box, Typography } from '@mui/material';
import NavBar from '../../../components/NavBar/NavBar';

const Perfil = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Obtener la sesión de forma asíncrona
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      const currentUser = data?.session?.user || null;
      setUser(currentUser);
      if (currentUser) {
        await fetchUserData(currentUser.id);
      }
      setLoading(false);
    };

    fetchSession();
    // Escuchar cambios de sesión
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        const currentUser = session?.user || null;
        setUser(currentUser);

        if (currentUser) {
          fetchUserData(currentUser.id);
        } else {
          setUserData(null);
        }
      }
    );

    // Limpiar suscripción al desmontar
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const fetchUserData = async (userId) => {
    const { data, error } = await supabase
      .from('perfiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user data:', error.message);
    } else {
      setUserData(data);
    }
  };

  if (loading) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  return (
    <div className="Perfil">
      <header>
        <NavBar></NavBar>
      </header>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h4" component="h2" gutterBottom mt={2}>
          Perfil
        </Typography>
        <Typography variant="p" component="p" gutterBottom mt={2}>
          Esta pagina esta protegida. Estas logeado!
        </Typography>
        {user ? (
          <>
            <Typography variant="h6" gutterBottom mt={2}>
              User UUID: {user.id}
            </Typography>
            {userData ? (
              <Box mt={2}>
                <Typography variant="body1">
                  Nombre: {userData.nombre}
                </Typography>
                <Typography variant="body1">
                  Apellido: {userData.apellido}
                </Typography>
                <Typography variant="body1">Grupo: {userData.grupo}</Typography>
              </Box>
            ) : (
              <Typography variant="body1">No user data found.</Typography>
            )}
          </>
        ) : (
          <Typography variant="body1">Loading user information...</Typography>
        )}
      </Box>
    </div>
  );
};

export default Perfil;
