import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  MenuItem,
  Tooltip,
  Button,
  Avatar,
  Menu,
  Typography,
  IconButton,
  Box,
} from '@mui/material';

function IconProfile() {
  const settings = ['Perfil', 'Dashboard', 'Logout'];
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const [session, setSession] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const initSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        supabase.auth.setSession(data.session);
        setSession(data.session);
      }
    };
    initSession();
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        if (session) {
          Cookies.set('sb-session', JSON.stringify(session), {
            expires: 7,
            secure: true,
            sameSite: 'Strict',
          });
        } else {
          Cookies.remove('sb-session');
        }
      }
    );
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setSession(null);
    Cookies.remove('sb-session');
    navigate('/');
  };

  const handleSettingClick = (setting) => {
    if (setting === 'Logout') {
      try {
        handleLogout();
      } catch (error) {}
    } else if (setting === 'Perfil') {
      navigate('/perfil');
    } else if (setting === 'Dashboard') {
      navigate('/Dashboard');
    }
  };

  return (
    <div className="IconButton">
      <Box sx={{ flexGrow: 0 }}>
        {session ? (
          <>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex', justifyContent: 'center' },
              }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar src="/broken-image.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => {
                      handleCloseUserMenu();
                      handleSettingClick(setting);
                    }}>
                    <Typography sx={{ textAlign: 'center' }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </>
        ) : (
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              navigate('/login');
            }}>
            Login
          </Button>
        )}
      </Box>
    </div>
  );
}
export default IconProfile;
