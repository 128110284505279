import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid2 from '@mui/material/Grid2';

const AddTrabajador = () => {
  const [open, setOpen] = useState(false);
  const [oficios, setOficios] = useState([]);
  const [barrios, setBarrios] = useState([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre_trabajador: '',
      apellido_trabajador: '',
      numero_wp_trabajador: '',
      direccion_trabajador: '',
      texto_trabajador: '',
      oficio: '',
      image_url: '',
      barrio: '',
    },
  });

  useEffect(() => {
    const fetchOficios = async () => {
      const { data, error } = await supabase
        .from('oficios')
        .select('id, nombre');
      if (error) {
        console.error('Error fetching oficios:', error.message);
      } else {
        setOficios(data);
      }
    };

    const fetchBarrios = async () => {
      const { data, error } = await supabase
        .from('barrios')
        .select('id, nombre');
      if (error) {
        console.error('Error fetching barrios:', error.message);
      } else {
        setBarrios(data);
      }
    };

    fetchOficios();
    fetchBarrios();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    const { error } = await supabase.from('products').insert([data]);
    if (error) {
      console.error('Error inserting data:', error.message);
    } else {
      console.log('Data inserted:', data);
    }
    handleClose();
  };

  return (
    <div>
      <Grid2 container m={2} spacing={2} direction={'column'}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Agregar Trabajador
        </Button>
      </Grid2>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Completar el Formulario</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="nombre_trabajador"
              control={control}
              rules={{ required: 'Nombre es requerido' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Nombre Trabajador"
                  type="text"
                  fullWidth
                  error={!!errors.nombre_trabajador}
                  helperText={errors.nombre_trabajador?.message}
                />
              )}
            />
            <Controller
              name="apellido_trabajador"
              control={control}
              rules={{ required: 'Apellido es requerido' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Apellido Trabajador"
                  type="text"
                  fullWidth
                  error={!!errors.apellido_trabajador}
                  helperText={errors.apellido_trabajador?.message}
                />
              )}
            />
            <Controller
              name="numero_wp_trabajador"
              control={control}
              rules={{ required: 'Número de WhatsApp es requerido' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Número WP Trabajador"
                  type="text"
                  fullWidth
                  error={!!errors.numero_wp_trabajador}
                  helperText={errors.numero_wp_trabajador?.message}
                />
              )}
            />
            <Controller
              name="direccion_trabajador"
              control={control}
              rules={{ required: 'Dirección es requerida' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Dirección Trabajador"
                  type="text"
                  fullWidth
                  error={!!errors.direccion_trabajador}
                  helperText={errors.direccion_trabajador?.message}
                />
              )}
            />
            <Controller
              name="texto_trabajador"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Texto Trabajador"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="oficio-label">Oficio</InputLabel>
              <Controller
                name="oficio"
                control={control}
                rules={{ required: 'Oficio es requerido' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="oficio-label"
                    label="Oficio"
                    error={!!errors.oficio}>
                    {oficios.map((oficio) => (
                      <MenuItem key={oficio.id} value={oficio.nombre}>
                        {oficio.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.oficio && (
                <p style={{ color: 'red' }}>{errors.oficio.message}</p>
              )}
            </FormControl>
            <Controller
              name="image_url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  label="Image URL"
                  type="text"
                  fullWidth
                />
              )}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="barrio-label">Barrio</InputLabel>
              <Controller
                name="barrio"
                control={control}
                rules={{ required: 'Barrio es requerido' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="barrio-label"
                    label="Barrio"
                    error={!!errors.barrio}>
                    {barrios.map((barrio) => (
                      <MenuItem key={barrio.id} value={barrio.nombre}>
                        {barrio.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.barrio && (
                <p style={{ color: 'red' }}>{errors.barrio.message}</p>
              )}
            </FormControl>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddTrabajador;
