// src/pages/ProductDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import { Container, Typography, CircularProgress } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      let { data: product, error } = await supabase
        .from('products')
        .select('*')
        .eq('id', id)
        .single();
      if (error) console.error(error);
      else setProduct(product);
      setLoading(false);
    };

    fetchProduct();
  }, [id]);

  if (loading) return <CircularProgress />;

  return (
    <div>
      <NavBar></NavBar>
      <Container>
        <Typography variant="h3" gutterBottom>
          {product.nombre_trabajador}
        </Typography>
        <img
          src={product.image_url}
          alt={product.nombre_trabajador}
          style={{ width: '25%', height: 'auto' }}
        />
        <Typography variant="body1" gutterBottom>
          {product.texto_trabajador}
        </Typography>
      </Container>
    </div>
  );
};

export default ProductDetail;
