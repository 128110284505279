// src/Auth.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../../utils/supabase';
import Cookies from 'js-cookie';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initSession = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session) {
        supabase.auth.setSession(data.session);
        setSession(data.session);
      }
    };
    initSession();
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
        if (session) {
          Cookies.set('sb-session', JSON.stringify(session), {
            expires: rememberMe ? 30 : 2,
            secure: true,
            sameSite: 'Strict',
          });
        } else {
          Cookies.remove('sb-session');
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [rememberMe]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) console.error('Error logging in:', error.message);
    else {
      navigate('/');
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setSession(null);
    Cookies.remove('sb-session');
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleBackToHome}
        sx={{ mt: 1 }}>
        Inicio
      </Button>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={8}
        className="Box">
        <Typography variant="h4" component="h1" gutterBottom>
          Bienvenido
        </Typography>
        <Typography variant="p" component="p" gutterBottom>
          Por favor inicia sesión para continuar.
        </Typography>
        {!session ? (
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              className="Checkbox"
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  name="rememberMe"
                  color="primary"
                />
              }
              label="Recordar mi contraseña"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}>
              Login
            </Button>
          </Box>
        ) : (
          <Button
            onClick={handleLogout}
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ mt: 3, mb: 2 }}>
            Logout
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default Auth;
