// src/components/SideBar/SideBar.js
import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import './SideBar.css';
import IconProfile from '../IconProfile/IconProfile';
import HandymanIcon from '@mui/icons-material/Handyman';

const SideBar = ({ setActiveComponent }) => {
  return (
    <div className="SideBar">
      <div className="Logo">
        <HandymanIcon
          className="logoNavBar"
          sx={{
            display: { xs: 'none', md: 'flex' },
            mr: 2,
          }}
        />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}>
          Laburito
        </Typography>
      </div>
      <IconProfile className="IconProfile" />
      <List className="List">
        <ListItem
          button
          onClick={() => setActiveComponent('AddTrabajador')}
          className="ListItem">
          <ListItemText primary="Trabajador" className="ListItemText" />
        </ListItem>
        <ListItem
          button
          onClick={() => setActiveComponent('AddBarrio')}
          className="ListItem">
          <ListItemText primary="Barrio" className="ListItemText" />
        </ListItem>
        <ListItem
          button
          onClick={() => setActiveComponent('AddOficio')}
          className="ListItem">
          <ListItemText primary="Oficio" className="ListItemText" />
        </ListItem>
      </List>
    </div>
  );
};

export default SideBar;
