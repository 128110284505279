// src/pages/ProductList.js
import React from 'react';
import NavBar from '../../components/NavBar/NavBar';
import { Typography } from '@mui/material';

const Home = () => {
  return (
    <div className="home">
      <header>
        <NavBar></NavBar>
      </header>
      <Typography variant="h4" component="h2" gutterBottom mt={2}>
        Bienvenido a Laburito.com
      </Typography>
    </div>
  );
};

export default Home;
