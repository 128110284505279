import React, {
  useEffect,
  useState,
  useCallback,
  lazy,
  Suspense,
  useRef,
} from 'react';
import { supabase } from '../../utils/supabase';
import {
  TextField,
  MenuItem,
  CircularProgress,
  Chip,
  Box,
  Typography,
  Grid2,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import './ProductList.css';

// Lazy load ProductCard
const ProductCard = lazy(() =>
  import('../../components/ProductCard/ProductCard')
);

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [oficios, setOficios] = useState([]);
  const [selectedBarrios, setSelectedBarrios] = useState([]);
  const [selectedOficios, setSelectedOficios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const observer = useRef();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [
          { data: products, error: productsError },
          { data: barrios, error: barriosError },
          { data: oficios, error: oficiosError },
        ] = await Promise.all([
          supabase.from('products').select('*').range(0, 19),
          supabase.from('barrios').select('*'),
          supabase.from('oficios').select('*'),
        ]);

        if (productsError) throw productsError;
        if (barriosError) throw barriosError;
        if (oficiosError) throw oficiosError;

        setProducts(products);
        setFilteredProducts(products);
        setBarrios(barrios);
        setOficios(oficios);
      } catch (error) {
        console.error(error);
      }
    };

    fetchInitialData();
  }, []);

  const fetchMoreData = useCallback(async () => {
    setLoading(true);
    try {
      const start = page * 20;
      const end = start + 19;
      const { data: newProducts, error } = await supabase
        .from('products')
        .select('*')
        .range(start, end);

      if (error) throw error;

      if (newProducts.length === 0) {
        setHasMore(false);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setFilteredProducts((prevProducts) => [
          ...prevProducts,
          ...newProducts,
        ]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [page]);

  const applyFilters = useCallback(() => {
    let filtered = products;
    if (selectedBarrios.length > 0) {
      filtered = filtered.filter((product) =>
        selectedBarrios.includes(product.barrio)
      );
    }
    if (selectedOficios.length > 0) {
      filtered = filtered.filter((product) =>
        selectedOficios.includes(product.oficio)
      );
    }
    setFilteredProducts(filtered);
  }, [products, selectedBarrios, selectedOficios]);

  useEffect(() => {
    applyFilters();
  }, [selectedBarrios, selectedOficios, applyFilters]);

  const handleProductClick = (productId) => {
    navigate(`/publicaciones/${productId}`);
  };

  const lastProductElementRef = useCallback(
    (node) => {
      if (loading || !hasMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, fetchMoreData]
  );

  const handleBarrioChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBarrios(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOficioChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOficios(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDelete = (filterType, value) => {
    if (filterType === 'barrio') {
      setSelectedBarrios((prev) => prev.filter((barrio) => barrio !== value));
    } else if (filterType === 'oficio') {
      setSelectedOficios((prev) => prev.filter((oficio) => oficio !== value));
    }
  };

  return (
    <div className="ProductList">
      <NavBar />
      <Box className="publicaciones">
        <Box className="filters" p={2}>
          <Box mt={2}>
            <Typography variant="subtitle1">Filtros Activos:</Typography>
            <Box>
              {selectedBarrios.map((barrio) => (
                <Chip
                  key={barrio}
                  label={barrio}
                  onDelete={() => handleDelete('barrio', barrio)}
                  style={{ margin: '2px' }}
                />
              ))}
              {selectedOficios.map((oficio) => (
                <Chip
                  key={oficio}
                  label={oficio}
                  onDelete={() => handleDelete('oficio', oficio)}
                  style={{ margin: '2px' }}
                />
              ))}
            </Box>
          </Box>

          <TextField
            className="filtro"
            select
            label="Filtrar por Barrio"
            value={selectedBarrios}
            onChange={handleBarrioChange}
            variant="outlined"
            fullWidth
            margin="normal"
            SelectProps={{
              multiple: true,
            }}>
            {barrios.map((barrio) => (
              <MenuItem key={barrio.id} value={barrio.nombre}>
                {barrio.nombre}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className="filtro"
            select
            label="Filtrar por Oficio"
            value={selectedOficios}
            onChange={handleOficioChange}
            variant="outlined"
            fullWidth
            margin="normal"
            SelectProps={{
              multiple: true,
            }}>
            {oficios.map((oficio) => (
              <MenuItem key={oficio.id} value={oficio.nombre}>
                {oficio.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Grid2 container spacing={3} p={3} className="product-grid">
          {filteredProducts.map((product, index) => {
            if (filteredProducts.length === index + 1) {
              return (
                <Grid2
                  xs={12}
                  sm={6}
                  md={3}
                  key={product.id}
                  ref={lastProductElementRef}
                  className="product-card">
                  <Suspense fallback={<CircularProgress />}>
                    <ProductCard
                      product={product}
                      onClick={() => handleProductClick(product.id)}
                    />
                  </Suspense>
                </Grid2>
              );
            } else {
              return (
                <Grid2
                  xs={12}
                  sm={6}
                  md={3}
                  key={product.id}
                  className="product-card">
                  <Suspense fallback={<CircularProgress />}>
                    <ProductCard
                      product={product}
                      onClick={() => handleProductClick(product.id)}
                    />
                  </Suspense>
                </Grid2>
              );
            }
          })}
        </Grid2>
      </Box>

      {loading && <CircularProgress />}
    </div>
  );
};

export default ProductList;
