// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ProductList from './pages/ProductList/ProductList';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Home from './pages/Home/Home';
import Postulate from './pages/Postulate/Postulate';
import Login from './pages/Login/Login';

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Dashboard from './pages/Protected/Dashboard/Dashboard';
import Perfil from './pages/Protected/Perfil/Perfil';

import './styles/App.css';

const App = () => {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/publicaciones" element={<ProductList />} />
          <Route path="/publicaciones/:id" element={<ProductDetail />} />
          <Route path="/postulate" element={<Postulate />} />
          <Route path="/login" element={<Login />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/perfil" element={<Perfil />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default App;
