// src/Dashboard.js
import React, { useState } from 'react';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import SideBar from '../../../components/SideBar/SideBar';
import AddTrabajador from '../../../components/AddTrabajdor/AddTrabajador';
import AddBarrio from '../../../components/AddBarrio/AddBarrio';
import AddOficio from '../../../components/AddOficio/AddOficio';

import './Dashboard.css';

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState('AddTrabajador');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'AddTrabajador':
        return <AddTrabajador />;
      case 'AddBarrio':
        return <AddBarrio />;
      case 'AddOficio':
        return <AddOficio />;
      default:
        return <div>Selecciona una opción del menú</div>;
    }
  };

  return (
    <div className="dashboard">
      <Grid2 className="dashBoardGrid">
        <Box className="sideBar">
          <SideBar setActiveComponent={setActiveComponent} />
        </Box>
        <Box className="dashBoardInteractive">
          <Box>{renderComponent()}</Box>
        </Box>
      </Grid2>
    </div>
  );
};

export default Dashboard;
