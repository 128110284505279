import React, { useState } from 'react';
import { supabase } from '../../utils/supabase'; // Asegúrate de tener tu configuración de Supabase en este archivo
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Grid2 } from '@mui/material';

const AddBarrio = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    creado_por: '',
    nombre: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const { data, error } = await supabase
      .from('barrios') // Asegúrate de que el nombre de la tabla es correcto
      .insert([
        {
          nombre: formData.nombre,
          creado_por: formData.creado_por,
        },
      ]);
    if (error) {
      console.error('Error inserting data:', error.message);
    } else {
      console.log('Data inserted:', data);
    }
    handleClose();
  };

  return (
    <div>
      <Grid2 container m={2} spacing={2} direction={'column'}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Agregar Barrio
        </Button>
      </Grid2>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Completar el Formulario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="creado_por"
            label="creado_por"
            type="text"
            fullWidth
            value={formData.creado_por}
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre Barrio"
            type="text"
            fullWidth
            value={formData.nombre}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddBarrio;
