// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabase';
import { Typography, Box } from '@mui/material';

const UserName = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Obtener la sesión de forma asíncrona
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      const currentUser = data?.session?.user || null;
      setUser(currentUser);
      if (currentUser) {
        await fetchUserData(currentUser.id);
      }
      setLoading(false);
    };

    fetchSession();
    // Escuchar cambios de sesión
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        const currentUser = session?.user || null;
        setUser(currentUser);

        if (currentUser) {
          fetchUserData(currentUser.id);
        } else {
          setUserData(null);
        }
      }
    );

    // Limpiar suscripción al desmontar
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const fetchUserData = async (userId) => {
    const { data, error } = await supabase
      .from('perfiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching user data:', error.message);
    } else {
      setUserData(data);
    }
  };

  if (loading) {
    return <Typography variant="body1">Loading...</Typography>;
  }

  return (
    <div className="UserName">
      {user ? (
        <>
          {userData ? (
            <Box pr={3}>
              <Typography variant="body1">Nombre: {userData.nombre}</Typography>
              <Typography variant="body1">Grupo: {userData.grupo}</Typography>
            </Box>
          ) : (
            <Typography variant="body1">No user data found.</Typography>
          )}
        </>
      ) : (
        <Typography variant="body1"></Typography>
      )}
    </div>
  );
};

export default UserName;
