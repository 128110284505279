// src/pages/ProductList.js
import React, { useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import { TextField, Box, Button, Typography } from '@mui/material';
import { supabase } from '../../utils/supabase';
import './Postulate.css';

const Postulate = () => {
  const [nombre_trabajador, setName] = useState('');
  const [apellido_trabajador, setApellido] = useState('');
  const [oficio_trabajador, setOficio] = useState('');
  const [numero_wp_trabajador, setNumero] = useState('');
  const [email_trabajador, setEmail] = useState('');
  const [mensaje_trabajador, setMensaje] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await supabase
      .from('postulaciones')
      .insert([
        {
          nombre_trabajador,
          apellido_trabajador,
          numero_wp_trabajador,
          oficio_trabajador,
          email_trabajador,
          mensaje_trabajador,
        },
      ]);

    if (error) {
      console.error('Error al enviar la consulta:', error);
    } else {
      setSubmitted(true);
      setName('');
      setOficio('');
      setApellido('');
      setEmail('');
      setMensaje('');
    }
  };

  return (
    <div className="postulate">
      <header>
        <NavBar></NavBar>
      </header>
      <h1>Dejanos tus datos para postularte</h1>
      <p>Al analizar los datos nos comunicaremos con usted</p>
      <Box sx={{ maxWidth: 500, mx: 'auto', mt: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Formulario de Consulta
        </Typography>
        {submitted ? (
          <Typography variant="body1">¡Gracias por tu consulta!</Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                value={nombre_trabajador}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
            <label>
              <TextField
                label="Apellido"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                value={apellido_trabajador}
                onChange={(e) => setApellido(e.target.value)}
                required
              />
            </label>
            <label>
              <TextField
                label="Oficio"
                variant="outlined"
                fullWidth
                margin="normal"
                type="text"
                value={oficio_trabajador}
                onChange={(e) => setOficio(e.target.value)}
                required
              />
            </label>
            <label>
              <TextField
                label="Telefono"
                variant="outlined"
                fullWidth
                margin="normal"
                type="tel"
                value={numero_wp_trabajador}
                onChange={(e) => setNumero(e.target.value)}
                required
              />
            </label>
            <label>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email_trabajador}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
            <label>
              <TextField
                label="Mensaje"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={mensaje_trabajador}
                onChange={(e) => setMensaje(e.target.value)}
                required
              />
            </label>
            <Button type="submit" variant="contained">
              Enviar
            </Button>
          </form>
        )}
      </Box>
    </div>
  );
};

export default Postulate;
